<template>
  <div>
    <div class="content">
      <div class="contentTop">
        <div class="contentTopTitle">库存列表</div>
        <div class="contentTopRight">
          <el-upload action="" :auto-upload="false" :multiple="false" :show-file-list="false" :on-change="uploadByJsqd"
            :file-list="fileList">
          <el-tooltip class="item" effect="dark" content="导入" placement="top">
            <img src="@/assets/images/production/leading-in.png" alt="">
          </el-tooltip>
          </el-upload>
          <el-input placeholder="" suffix-icon="el-icon-search" size="small" v-model="input2" @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="showId" label="序号" fixed="left" width="80">
        </el-table-column>
        <el-table-column prop="resourceCode" label="物料编码">
        </el-table-column>
        <el-table-column prop="resourceName" label="产品名称">
        </el-table-column>
        <el-table-column prop="partCode" label="图号">
        </el-table-column>
        <el-table-column prop="versionCode" label="版本号">
        </el-table-column>
        <el-table-column prop="materialTexture" label="材质">
        </el-table-column>
        <el-table-column prop="model" label="规格型号">
        </el-table-column>
        <el-table-column prop="category" label="分类">
        </el-table-column>
        <el-table-column prop="quantity" label="数量">
        </el-table-column>
        <el-table-column prop="quantityUnit" label="单位">
        </el-table-column>
      </el-table>
      <div class="totalFlex">
        <span>共{{ total }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40]" :page-size=pageChange
            layout="prev, pager, next,sizes, jumper" :total=total>
          </el-pagination> </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getInventoryInfoData, importInventoryInfo, searchInventoryInfoData } from '@/api/data.js'
export default {
  name: "inventoryManagement",
  data() {
    return {
      status: false,
      tableData: [],
      total: 0, //总条数，用于分组组件
      currentPage1: 1,
      input2: '',
      tempData: [],
      tempData2: [],  //筛选后的数据
      section: [
        '已确认',
        '未确认'
      ],
      limitNum: 1, //文件上传个数限制
      fileList: [], //文件列表
      pageChange: 10,   //当前页面多少条工单
      tempTotal: 0, //总条数，但与分页组件无关
      searchStatus: false,
      searchData: []
    }
  },
  methods: {
    // 分页组件--一页显示多少条数据
    async handleSizeChange(val) {
      this.pageChange = val
      if (this.searchStatus) {
        this.tempData = this.searchData.filter((v, i) => i < val)
      } else {
        const res = await getInventoryInfoData(1, val)
        const { records } = res.data
        this.tableData = records
        this.tempData = this.tableData
      }
      this.currentPage1 = 1
    },
    // 分页组件--选择页数
    async handleCurrentChange(val) {
      if (this.searchStatus) {
        this.tempData = this.searchData.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else {
        const res = await getInventoryInfoData(val, this.pageChange)
        const { records } = res.data
        this.tableData = records
        this.tempData = this.tableData
      }
      this.currentPage1 = val
    },
    async searchHandler() {
      const res = await searchInventoryInfoData(1, this.tempTotal, this.input2)
      // 搜索后的总条数
      this.searchData = res.data.records
      this.searchStatus = true
      this.pageChange = 10
      this.currentPage1 = 1
      this.tempData = this.searchData.filter((v, i) => i < 10)
      this.total = this.searchData.length
    },
    async renovate() {
      // 刷新，重新赋值数据
      const res = await getInventoryInfoData(1, 10)
      const { records } = res.data
      this.tableData = records
      this.total = res.data.total
      this.tempData = this.tableData
      this.tempTotal = res.data.total
      this.pageChange = 10
      this.currentPage1 = 1
      this.status = false
      this.searchStatus = false
      this.input2 = ''
    },
    beforeAvatarUpload(file) {
      let fileArr = file.name.split('.')
      let suffix = fileArr[fileArr.length - 1]
      if (!/(xls|xlsx)/i.test(suffix)) {
        this.$message('文件格式不正确')
        return false
      }
      if (file.size > 10 * 1024 * 1024) {
        this.$message('文件过大，请上传小于10MB的文件〜')
        return false
      }
      return true
    },
    uploadByJsqd(file) {
      if (this.beforeAvatarUpload(file)) {
        this.fileList.name = file.name
        this.fileList.url = ''
        let formdata = new FormData()
        formdata.append('file', file.raw)
        importInventoryInfo(formdata).then((res) => {
          if (res.data === 'ok') {
            this.$message({
              message: '工单导入成功！',
              type: 'success'
            })
            this.renovate()
          }
        }, (err) => {
          console.log('工单导入err错误:', err)
        })
      }
    }
  },
  async activated() {
    await this.renovate()
  }
}
</script>

<style lang="scss" scoped>
@import './style/style.scss'
</style>
